'use client'
import { Display } from '../../../common/style/Display'
import { theme } from '../../../styles/theme'

export function ShopSupplementsHero() {
  return (
    <>
      <Display isNotebookUp>
        <svg width="349" height="253" viewBox="0 0 349 253" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_11709_22347)">
            <rect width="318" height="253" rx="8" transform="matrix(-1 0 0 1 318 0)" fill="#EED5D5" />
            <path
              d="M317.894 102.306C326.475 204.849 315.822 247.39 300.585 317.293L-460.463 317.293C-542.301 -120.292 -199.737 121.69 117.428 128.189C205.006 129.984 307.167 -25.8724 317.894 102.306Z"
              fill="#EBCCCC"
            />
            <g opacity="0.9">
              <g style={{ mixBlendMode: 'multiply' }} opacity="0.4">
                <ellipse
                  cx="36.7389"
                  cy="6.02919"
                  rx="36.7389"
                  ry="6.02919"
                  transform="matrix(-1 0 0 1 155.235 83.7783)"
                  fill="#D9D9D9"
                />
              </g>
              <g style={{ mixBlendMode: 'multiply' }} opacity="0.4">
                <ellipse
                  cx="36.7389"
                  cy="5.59546"
                  rx="36.7389"
                  ry="5.59546"
                  transform="matrix(-1 0 0 1 197.085 112.841)"
                  fill="#D9D9D9"
                />
              </g>
              <ellipse
                cx="38.2533"
                cy="40.2988"
                rx="38.2533"
                ry="40.2988"
                transform="matrix(-0.809017 -0.587785 -0.587785 0.809017 193.289 36.969)"
                fill="#EAE2E2"
                stroke="#EAE2E2"
                strokeWidth="3.06213"
              />
              <ellipse
                cx="38.2533"
                cy="40.2988"
                rx="38.2533"
                ry="40.2988"
                transform="matrix(-0.809017 -0.587785 -0.587785 0.809017 196.977 39.6228)"
                fill="#DED2D2"
                stroke="#EAE2E2"
                strokeWidth="3.06213"
              />
              <ellipse
                cx="31.0544"
                cy="32.7149"
                rx="31.0544"
                ry="32.7149"
                transform="matrix(-0.809017 -0.587785 -0.587785 0.809017 186.843 41.1467)"
                fill="#D5C8C8"
              />
              <ellipse
                cx="12.553"
                cy="13.2243"
                rx="12.553"
                ry="13.2243"
                transform="matrix(-0.809017 -0.587785 -0.587785 0.809017 159.396 45.2314)"
                fill="#DED2D2"
              />
              <ellipse
                cx="12.553"
                cy="13.2243"
                rx="12.553"
                ry="13.2243"
                transform="matrix(-0.809017 -0.587785 -0.587785 0.809017 160.419 46.0398)"
                fill="#EAE2E2"
              />
              <path d="M189.213 82.8594L143.718 49.8651" stroke="#CBBABA" strokeWidth="14.29" strokeLinecap="round" />
              <path d="M191.114 79.0603L145.619 46.066" stroke="#EAE1E1" strokeWidth="2.04142" strokeLinecap="round" />
              <ellipse
                cx="38.2533"
                cy="40.2988"
                rx="38.2533"
                ry="40.2988"
                transform="matrix(-0.809017 -0.587785 -0.587785 0.809017 234.333 67.3247)"
                fill="#EAE2E2"
                stroke="#EAE2E2"
                strokeWidth="3.06213"
              />
              <ellipse
                cx="38.2533"
                cy="40.2988"
                rx="38.2533"
                ry="40.2988"
                transform="matrix(-0.809017 -0.587785 -0.587785 0.809017 237.77 69.7952)"
                fill="#DED2D2"
                stroke="#EAE2E2"
                strokeWidth="3.06213"
              />
              <ellipse
                cx="31.0544"
                cy="32.7149"
                rx="31.0544"
                ry="32.7149"
                transform="matrix(-0.809017 -0.587785 -0.587785 0.809017 227.488 71.6992)"
                fill="#D5C8C8"
              />
              <ellipse
                cx="25.2227"
                cy="26.5714"
                rx="25.2227"
                ry="26.5714"
                transform="matrix(-0.809017 -0.587785 -0.587785 0.809017 221.466 75.0361)"
                fill="#EAE2E2"
                stroke="#EAE2E2"
                strokeWidth="3.06213"
              />
              <ellipse
                cx="25.2227"
                cy="26.5714"
                rx="25.2227"
                ry="26.5714"
                transform="matrix(-0.809017 -0.587785 -0.587785 0.809017 225.083 77.5938)"
                fill="#D7C8C8"
                stroke="#EAE2E2"
                strokeWidth="3.06213"
              />
              <ellipse
                cx="19.2054"
                cy="20.2324"
                rx="19.2054"
                ry="20.2324"
                transform="matrix(-0.809017 -0.587785 -0.587785 0.809017 217.262 79.1853)"
                fill="#C8B8B8"
              />
              <ellipse
                cx="7.28182"
                cy="7.6712"
                rx="7.28182"
                ry="7.6712"
                transform="matrix(-0.809017 -0.587785 -0.587785 0.809017 201.601 82.6592)"
                fill="#D4CBCB"
                stroke="#EAE2E2"
                strokeWidth="3.06213"
              />
              <ellipse
                cx="7.28182"
                cy="7.6712"
                rx="7.28182"
                ry="7.6712"
                transform="matrix(-0.809017 -0.587785 -0.587785 0.809017 202.984 83.5962)"
                fill="#D5C8C8"
                stroke="#EAE2E2"
                strokeWidth="3.06213"
              />
            </g>
            <g style={{ mixBlendMode: 'multiply' }} opacity="0.4">
              <ellipse
                cx="76.4352"
                cy="9.32632"
                rx="76.4352"
                ry="9.32632"
                transform="matrix(-1 0 0 1 158.398 167.182)"
                fill="#D9D9D9"
              />
            </g>
          </g>
          <g style={{ mixBlendMode: 'multiply' }} opacity="0.4">
            <ellipse
              cx="46.9381"
              cy="4.73586"
              rx="46.9381"
              ry="4.73586"
              transform="matrix(-1 0 0 1 106.407 220.445)"
              fill="#D9D9D9"
            />
          </g>
          <g style={{ mixBlendMode: 'multiply' }} opacity="0.4">
            <ellipse
              cx="80.8486"
              cy="6.12932"
              rx="80.8486"
              ry="6.12932"
              transform="matrix(-1 0 0 1 332.697 209.02)"
              fill="#D9D9D9"
            />
          </g>
          <path
            d="M76.4715 111.456L39.3737 158.444C34.2257 164.964 35.0209 174.159 42.83 176.993C54.0068 181.049 70.4442 183.458 89.3344 183.458C108.141 183.458 125.148 181.07 136.955 177.046C145.186 174.241 146.168 164.623 140.661 157.893L102.479 111.23C95.7285 102.979 83.0775 103.089 76.4715 111.456Z"
            fill="#FEEFE4"
          />
          <path
            d="M142.193 168.695L99.7909 116.961C86.3662 104.354 103.229 144.792 104.702 154.615C106.176 164.438 90.1317 178.19 104.702 177.536C116.359 177.012 134.553 171.423 142.193 168.695Z"
            fill="#FBE6D6"
          />
          <path
            d="M133.099 147.445L109.357 177.784C105.361 182.89 106.026 190.024 112.172 192.091C119.872 194.682 130.787 196.207 143.24 196.207C155.628 196.207 166.888 194.698 174.998 192.132C181.47 190.084 182.28 182.624 178.004 177.352L153.605 147.267C148.299 140.725 138.29 140.812 133.099 147.445Z"
            fill="#FEEFE4"
          />
          <path
            d="M174.215 179.502L150.614 150.706C141.527 142.173 152.941 169.546 153.939 176.195C154.936 182.845 144.075 192.154 153.939 191.711C159.037 191.481 165.982 189.821 171.654 188.177C175.42 187.085 176.701 182.534 174.215 179.502Z"
            fill="#FBE6D6"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M226.944 160.605C226.944 159.728 227.225 157.109 226.529 156.432C224.218 154.183 217.928 155.032 212.581 156.432L114.909 183.909C111.844 184.739 110.365 187.055 111.195 190.119C112.025 193.183 113.394 195.313 116.458 194.483L223.922 164.177C225.937 163.33 226.944 162.441 226.944 160.605Z"
            fill="#B1B1B1"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M226.944 157.676C226.944 153.885 219.539 151.682 212.581 153.503L114.909 180.98C111.845 181.81 107.061 188.49 107.891 191.554C108.721 194.618 113.394 192.384 116.458 191.554L223.922 161.248C225.937 160.401 226.944 159.512 226.944 157.676Z"
            fill="#BFBFBF"
          />
          <ellipse
            cx="29.459"
            cy="7.18513"
            rx="29.459"
            ry="7.18513"
            transform="matrix(-1 0 0 1 116.743 215.546)"
            fill="#BFBFBF"
          />
          <rect width="58.918" height="42.2681" transform="matrix(-1 0 0 1 116.743 180.463)" fill="#BFBFBF" />
          <path
            d="M61.0843 221.049V194.944C61.0843 188.835 75.7443 196.776 75.7443 200.441C75.7443 204.106 78.7984 222.279 83.227 223.348C87.6555 224.417 93.0003 226.494 80.7837 226.647C72.1215 226.755 65.2987 224.68 62.1227 222.93C61.4455 222.558 61.0843 221.822 61.0843 221.049Z"
            fill="#B1B1B1"
          />
          <ellipse
            cx="29.459"
            cy="7.18513"
            rx="29.459"
            ry="7.18513"
            transform="matrix(-1 0 0 1 116.743 173.302)"
            fill="#BFBFBF"
          />
          <path
            d="M61.2212 179.755C61.2212 182.153 73.1336 184.097 87.8282 184.097C93.268 184.097 98.3265 183.83 102.541 183.373C102.541 183.373 113.919 181.897 100.729 180.567C97.9235 180.285 89.4407 178.222 83.7968 176.136C74.1216 172.56 61.2212 177.356 61.2212 179.755Z"
            fill="#FEEFE4"
          />
          <path
            d="M260.293 74.9987C260.293 70.0898 264.273 66.1104 269.182 66.1104H304.735C309.644 66.1104 313.623 70.0898 313.623 74.9986V77.2207C313.623 80.9024 316.608 83.887 320.289 83.887C331.334 83.887 340.288 92.8407 340.288 103.886V199.435C340.288 210.48 331.334 219.434 320.289 219.434H253.627C242.582 219.434 233.628 210.48 233.628 199.435V103.886C233.628 92.8407 242.582 83.887 253.627 83.887C257.309 83.887 260.293 80.9024 260.293 77.2207V74.9987Z"
            fill="#E29FAB"
          />
          <rect width="106.66" height="85.5499" transform="matrix(-1 0 0 1 340.288 108.33)" fill="#FFF6F5" />
          <g style={{ mixBlendMode: 'lighten' }} opacity="0.1">
            <path
              d="M335.634 107.594V105.772C335.634 96.8011 326.765 87.7512 317.264 87.7512C317.264 87.7512 308.207 87.7512 308.499 94.0168C317.446 93.471 325.264 100.001 326.321 108.901L326.883 113.627C337.234 113.627 335.634 109.808 335.634 107.594Z"
              fill={theme.colors.grayscalewhite}
            />
          </g>
          <rect width="59.0217" height="11.3159" transform="matrix(-1 0 0 1 302 122.525)" fill="#F2E2E0" />
          <rect width="36.6804" height="11.3159" transform="matrix(-1 0 0 1 280 139.281)" fill="#F2E2E0" />
          <rect width="51.5746" height="3.86878" transform="matrix(-1 0 0 1 295 165.346)" fill="#F2E2E0" />
          <rect width="59.0217" height="3.86878" transform="matrix(-1 0 0 1 302 172.793)" fill="#F2E2E0" />
          <rect width="29.2333" height="3.86878" transform="matrix(-1 0 0 1 272 180.24)" fill="#F2E2E0" />
          <path
            d="M322.511 61.6662C322.511 57.9846 319.526 55 315.845 55H258.071C254.389 55 251.405 57.9846 251.405 61.6662V72.7766C251.405 75.231 253.394 77.2207 255.849 77.2207H318.067C320.521 77.2207 322.511 75.231 322.511 72.7766V61.6662Z"
            fill="#3F4458"
          />
          <path d="M317.511 61.666L317.511 73.8874" stroke="#5B5F70" strokeWidth="1.11104" strokeLinecap="round" />
          <path d="M311.956 61.666L311.956 73.8874" stroke="#5B5F70" strokeWidth="1.11104" strokeLinecap="round" />
          <path d="M306.402 61.666L306.402 73.8874" stroke="#5B5F70" strokeWidth="1.11104" strokeLinecap="round" />
          <path d="M300.846 61.666L300.846 73.8874" stroke="#5B5F70" strokeWidth="1.11104" strokeLinecap="round" />
          <path d="M295.291 61.666L295.291 73.8874" stroke="#5B5F70" strokeWidth="1.11104" strokeLinecap="round" />
          <path d="M289.735 61.666L289.735 73.8874" stroke="#5B5F70" strokeWidth="1.11104" strokeLinecap="round" />
          <path d="M284.18 61.666L284.18 73.8874" stroke="#5B5F70" strokeWidth="1.11104" strokeLinecap="round" />
          <path d="M278.625 61.666L278.625 73.8874" stroke="#5B5F70" strokeWidth="1.11104" strokeLinecap="round" />
          <path d="M273.07 61.666L273.07 73.8874" stroke="#5B5F70" strokeWidth="1.11104" strokeLinecap="round" />
          <path d="M267.515 61.666L267.515 73.8874" stroke="#5B5F70" strokeWidth="1.11104" strokeLinecap="round" />
          <path d="M261.959 61.666L261.959 73.8874" stroke="#5B5F70" strokeWidth="1.11104" strokeLinecap="round" />
          <path d="M256.404 61.666L256.404 73.8874" stroke="#5B5F70" strokeWidth="1.11104" strokeLinecap="round" />
          <g style={{ mixBlendMode: 'multiply' }} opacity="0.1">
            <path
              d="M233.782 200.683V102.979C233.782 94.0085 242.651 84.0278 252.152 84.0278C261.653 84.0278 260.358 76.7298 260.358 73.2593C261.382 73.2593 296.561 73.3241 297.05 75.9475C296.882 77.7751 276.726 79.3697 276.448 80.6314C274.659 88.7568 270.76 86.3563 266.861 88.9556C261.234 93.9229 258.011 101.067 258.011 108.573V192.333C258.011 199.349 259.993 206.223 263.73 212.162L268.295 219.418H254.061C242.835 219.418 233.782 210.395 233.782 200.683Z"
              fill="#777777"
            />
          </g>
          <defs>
            <clipPath id="clip0_11709_22347">
              <rect
                width="318"
                height="253"
                rx="8"
                transform="matrix(-1 0 0 1 318 0)"
                fill={theme.colors.grayscalewhite}
              />
            </clipPath>
          </defs>
        </svg>
      </Display>
      <Display isNotebookDown>
        <svg width="100%" height="100%" viewBox="0 0 342 116" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_11369_60340)">
            <rect width="342" height="101.691" rx="4.54485" transform="matrix(-1 0 0 1 342 0.325439)" fill="#EED5D5" />
            <path
              d="M327.469 34.9641C330.925 76.2656 326.635 93.3998 320.497 121.555L13.9678 121.555C-18.9944 -54.6926 118.981 42.7715 246.727 45.389C282.001 46.1117 323.149 -16.6628 327.469 34.9641Z"
              fill="#EBCCCC"
            />
            <g style={{ mixBlendMode: 'multiply' }} opacity="0.4">
              <ellipse
                cx="20.4481"
                cy="3.35572"
                rx="20.4481"
                ry="3.35572"
                transform="matrix(-1 0 0 1 208.613 50.8962)"
                fill="#D9D9D9"
              />
            </g>
            <g style={{ mixBlendMode: 'multiply' }} opacity="0.4">
              <ellipse
                cx="20.4481"
                cy="3.11432"
                rx="20.4481"
                ry="3.11432"
                transform="matrix(-1 0 0 1 231.906 67.0715)"
                fill="#D9D9D9"
              />
            </g>
            <g opacity="0.9">
              <ellipse
                cx="21.291"
                cy="22.4294"
                rx="21.291"
                ry="22.4294"
                transform="matrix(-0.809017 -0.587785 -0.587785 0.809017 202.682 25.3542)"
                fill="#EAE2E2"
                stroke="#EAE2E2"
                strokeWidth="1.70432"
              />
              <ellipse
                cx="21.291"
                cy="22.4294"
                rx="21.291"
                ry="22.4294"
                transform="matrix(-0.809017 -0.587785 -0.587785 0.809017 204.735 26.8311)"
                fill="#DED2D2"
                stroke="#EAE2E2"
                strokeWidth="1.70432"
              />
              <ellipse
                cx="17.2842"
                cy="18.2084"
                rx="17.2842"
                ry="18.2084"
                transform="matrix(-0.809017 -0.587785 -0.587785 0.809017 199.094 27.6797)"
                fill="#D5C8C8"
              />
              <ellipse
                cx="6.98675"
                cy="7.36035"
                rx="6.98675"
                ry="7.36035"
                transform="matrix(-0.809017 -0.587785 -0.587785 0.809017 183.818 29.9529)"
                fill="#DED2D2"
              />
              <ellipse
                cx="6.98675"
                cy="7.36035"
                rx="6.98675"
                ry="7.36035"
                transform="matrix(-0.809017 -0.587785 -0.587785 0.809017 184.387 30.4028)"
                fill="#EAE2E2"
              />
              <path d="M200.413 50.8958L175.091 32.5318" stroke="#CBBABA" strokeWidth="7.95349" strokeLinecap="round" />
              <path d="M201.471 48.7812L176.15 30.4173" stroke="#EAE1E1" strokeWidth="1.13621" strokeLinecap="round" />
              <ellipse
                cx="21.291"
                cy="22.4294"
                rx="21.291"
                ry="22.4294"
                transform="matrix(-0.809017 -0.587785 -0.587785 0.809017 225.526 42.2498)"
                fill="#EAE2E2"
                stroke="#EAE2E2"
                strokeWidth="1.70432"
              />
              <ellipse
                cx="21.291"
                cy="22.4294"
                rx="21.291"
                ry="22.4294"
                transform="matrix(-0.809017 -0.587785 -0.587785 0.809017 227.439 43.6248)"
                fill="#DED2D2"
                stroke="#EAE2E2"
                strokeWidth="1.70432"
              />
              <ellipse
                cx="17.2842"
                cy="18.2084"
                rx="17.2842"
                ry="18.2084"
                transform="matrix(-0.809017 -0.587785 -0.587785 0.809017 221.717 44.6848)"
                fill="#D5C8C8"
              />
              <ellipse
                cx="14.0384"
                cy="14.7891"
                rx="14.0384"
                ry="14.7891"
                transform="matrix(-0.809017 -0.587785 -0.587785 0.809017 218.365 46.542)"
                fill="#EAE2E2"
                stroke="#EAE2E2"
                strokeWidth="1.70432"
              />
              <ellipse
                cx="14.0384"
                cy="14.7891"
                rx="14.0384"
                ry="14.7891"
                transform="matrix(-0.809017 -0.587785 -0.587785 0.809017 220.378 47.9656)"
                fill="#D7C8C8"
                stroke="#EAE2E2"
                strokeWidth="1.70432"
              />
              <ellipse
                cx="10.6893"
                cy="11.2609"
                rx="10.6893"
                ry="11.2609"
                transform="matrix(-0.809017 -0.587785 -0.587785 0.809017 216.025 48.8508)"
                fill="#C8B8B8"
              />
              <ellipse
                cx="4.05291"
                cy="4.26962"
                rx="4.05291"
                ry="4.26962"
                transform="matrix(-0.809017 -0.587785 -0.587785 0.809017 207.309 50.7847)"
                fill="#D4CBCB"
                stroke="#EAE2E2"
                strokeWidth="1.70432"
              />
              <ellipse
                cx="4.05291"
                cy="4.26962"
                rx="4.05291"
                ry="4.26962"
                transform="matrix(-0.809017 -0.587785 -0.587785 0.809017 208.078 51.3062)"
                fill="#D5C8C8"
                stroke="#EAE2E2"
                strokeWidth="1.70432"
              />
            </g>
            <g style={{ mixBlendMode: 'multiply' }} opacity="0.4">
              <ellipse
                cx="32.9301"
                cy="3.3225"
                rx="32.9301"
                ry="3.3225"
                transform="matrix(-1 0 0 1 155.823 80.5469)"
                fill="#D9D9D9"
              />
            </g>
          </g>
          <g style={{ mixBlendMode: 'multiply' }} opacity="0.4">
            <ellipse
              cx="32.9301"
              cy="3.3225"
              rx="32.9301"
              ry="3.3225"
              transform="matrix(-1 0 0 1 135.685 105.02)"
              fill="#D9D9D9"
            />
          </g>
          <g style={{ mixBlendMode: 'multiply' }} opacity="0.4">
            <ellipse
              cx="49.3406"
              cy="3.74063"
              rx="49.3406"
              ry="3.74063"
              transform="matrix(-1 0 0 1 338.438 106.944)"
              fill="#D9D9D9"
            />
          </g>
          <path
            d="M64.1705 28.558L38.1441 61.5225C34.5325 66.0969 35.0903 72.5474 40.5689 74.5358C48.4101 77.3816 59.942 79.0719 73.1947 79.0719C86.3889 79.0719 98.3205 77.3965 106.604 74.5734C112.378 72.6053 113.067 65.8579 109.204 61.1363L82.4168 28.3989C77.6806 22.6105 68.8051 22.6879 64.1705 28.558Z"
            fill="#FEEFE4"
          />
          <path
            d="M110.279 68.7139L80.5309 32.4191C71.1126 23.5751 82.9429 51.9448 83.9766 58.8362C85.0103 65.7277 73.7543 75.3757 83.9766 74.9162C92.1545 74.5487 104.919 70.6282 110.279 68.7139Z"
            fill="#FBE6D6"
          />
          <path
            d="M103.899 53.8059L87.2422 75.0904C84.4389 78.6727 84.9054 83.6777 89.2168 85.1281C94.6191 86.9454 102.276 88.0151 111.013 88.0151C119.704 88.0151 127.604 86.9566 133.293 85.1566C137.834 83.72 138.402 78.4863 135.403 74.7874L118.285 53.6807C114.563 49.0912 107.54 49.1524 103.899 53.8059Z"
            fill="#FEEFE4"
          />
          <path
            d="M132.744 76.2961L116.186 56.0943C109.811 50.1077 117.819 69.3117 118.519 73.9766C119.219 78.6415 111.599 85.1724 118.519 84.8614C122.095 84.7007 126.968 83.536 130.947 82.3823C133.59 81.6163 134.488 78.4237 132.744 76.2961Z"
            fill="#FBE6D6"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M186.837 63.0388C186.837 62.4234 187.034 60.5859 186.546 60.1109C184.924 58.5333 180.512 59.129 176.76 60.111L108.237 79.3882C106.087 79.9704 105.049 81.595 105.631 83.7447C106.214 85.8944 107.174 87.3887 109.324 86.8065L184.717 65.545C186.13 64.9505 186.837 64.3271 186.837 63.0388Z"
            fill="#B1B1B1"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M186.837 60.984C186.837 58.3245 181.641 56.7785 176.76 58.0561L108.237 77.3333C106.087 77.9155 102.731 82.6019 103.313 84.7516C103.896 86.9013 107.174 85.3338 109.324 84.7516L184.717 63.4901C186.13 62.8956 186.837 62.2722 186.837 60.984Z"
            fill="#BFBFBF"
          />
          <ellipse
            cx="20.6674"
            cy="5.04082"
            rx="20.6674"
            ry="5.04082"
            transform="matrix(-1 0 0 1 109.523 101.583)"
            fill="#BFBFBF"
          />
          <rect width="41.3347" height="29.6538" transform="matrix(-1 0 0 1 109.523 76.9707)" fill="#BFBFBF" />
          <path
            d="M70.4757 105.444V87.1299C70.4757 82.8444 80.7607 88.4155 80.7607 90.9867C80.7607 93.558 82.9034 106.307 86.0103 107.057C89.1172 107.807 92.8669 109.265 84.2961 109.372C78.2191 109.448 73.4324 107.991 71.2043 106.764C70.7292 106.503 70.4757 105.987 70.4757 105.444Z"
            fill="#B1B1B1"
          />
          <ellipse
            cx="20.6674"
            cy="5.04082"
            rx="20.6674"
            ry="5.04082"
            transform="matrix(-1 0 0 1 109.523 71.9463)"
            fill="#BFBFBF"
          />
          <path
            d="M70.5718 76.4734C70.5718 78.1559 78.929 79.5198 89.2382 79.5198C93.0546 79.5198 96.6035 79.3328 99.5599 79.012C99.5599 79.012 107.542 77.9762 98.2886 77.0436C96.3207 76.8453 90.3695 75.3984 86.41 73.9348C79.6222 71.4259 70.5718 74.7909 70.5718 76.4734Z"
            fill="#FEEFE4"
          />
          <path
            d="M252.248 25.1527C252.248 22.1569 254.676 19.7283 257.672 19.7283H279.37C282.365 19.7283 284.794 22.1568 284.794 25.1527V26.5088C284.794 28.7556 286.615 30.577 288.862 30.577C295.603 30.577 301.067 36.0413 301.067 42.7819V101.094C301.067 107.835 295.603 113.299 288.862 113.299H248.179C241.439 113.299 235.975 107.835 235.975 101.094V42.7819C235.975 36.0414 241.439 30.577 248.179 30.577C250.426 30.577 252.248 28.7556 252.248 26.5088V25.1527Z"
            fill="#E29FAB"
          />
          <rect width="65.0926" height="52.2097" transform="matrix(-1 0 0 1 301.067 45.4937)" fill="#FFF6F5" />
          <g style={{ mixBlendMode: 'multiply' }} opacity="0.1">
            <path
              d="M236.069 101.856V42.2286C236.069 36.754 241.481 30.6629 247.279 30.6629C253.078 30.6629 252.288 26.2091 252.288 24.0911C252.912 24.0911 274.381 24.1306 274.68 25.7316C274.577 26.847 262.276 27.8201 262.107 28.5902C261.015 33.5489 258.636 32.084 256.256 33.6703C252.822 36.7017 250.855 41.0619 250.855 45.6426V96.7597C250.855 101.042 252.065 105.237 254.345 108.861L257.131 113.289H248.445C241.594 113.289 236.069 107.783 236.069 101.856Z"
              fill="#777777"
            />
          </g>
          <g style={{ mixBlendMode: 'lighten' }} opacity="0.1">
            <path
              d="M298.227 45.0453V43.9329C298.227 38.4583 292.815 32.9353 287.016 32.9353C287.016 32.9353 281.489 32.9353 281.667 36.7591C287.127 36.426 291.899 40.4108 292.544 45.8428L292.887 48.7266C299.204 48.7266 298.227 46.3963 298.227 45.0453Z"
              fill={theme.colors.grayscalewhite}
            />
          </g>
          <rect width="36.02" height="6.9059" transform="matrix(-1 0 0 1 294.647 54.1572)" fill="#F2E2E0" />
          <rect width="22.3854" height="6.9059" transform="matrix(-1 0 0 1 294.647 64.3828)" fill="#F2E2E0" />
          <rect width="31.4751" height="2.36105" transform="matrix(-1 0 0 1 294.647 80.2903)" fill="#F2E2E0" />
          <rect width="36.02" height="2.36105" transform="matrix(-1 0 0 1 294.647 84.8352)" fill="#F2E2E0" />
          <rect width="17.8406" height="2.36105" transform="matrix(-1 0 0 1 294.647 89.3799)" fill="#F2E2E0" />
          <path
            d="M290.218 17.016C290.218 14.7692 288.397 12.9478 286.15 12.9478H250.891C248.644 12.9478 246.823 14.7692 246.823 17.016V23.7965C246.823 25.2944 248.037 26.5087 249.535 26.5087H287.506C289.004 26.5087 290.218 25.2944 290.218 23.7965V17.016Z"
            fill="#3F4458"
          />
          <path d="M287.167 17.0156L287.167 24.4742" stroke="#5B5F70" strokeWidth="0.678048" strokeLinecap="round" />
          <path d="M283.777 17.0159L283.777 24.4744" stroke="#5B5F70" strokeWidth="0.678048" strokeLinecap="round" />
          <path d="M280.387 17.0159L280.387 24.4744" stroke="#5B5F70" strokeWidth="0.678048" strokeLinecap="round" />
          <path d="M276.996 17.0156L276.996 24.4742" stroke="#5B5F70" strokeWidth="0.678048" strokeLinecap="round" />
          <path d="M273.606 17.0159L273.606 24.4744" stroke="#5B5F70" strokeWidth="0.678048" strokeLinecap="round" />
          <path d="M270.216 17.0156L270.216 24.4742" stroke="#5B5F70" strokeWidth="0.678048" strokeLinecap="round" />
          <path d="M266.825 17.0156L266.825 24.4742" stroke="#5B5F70" strokeWidth="0.678048" strokeLinecap="round" />
          <path d="M263.435 17.0159L263.435 24.4744" stroke="#5B5F70" strokeWidth="0.678048" strokeLinecap="round" />
          <path d="M260.045 17.0159L260.045 24.4744" stroke="#5B5F70" strokeWidth="0.678048" strokeLinecap="round" />
          <path d="M256.655 17.0156L256.655 24.4742" stroke="#5B5F70" strokeWidth="0.678048" strokeLinecap="round" />
          <path d="M253.265 17.0159L253.265 24.4744" stroke="#5B5F70" strokeWidth="0.678048" strokeLinecap="round" />
          <path d="M249.874 17.0156L249.874 24.4742" stroke="#5B5F70" strokeWidth="0.678048" strokeLinecap="round" />
          <defs>
            <clipPath id="clip0_11369_60340">
              <rect
                width="342"
                height="101.691"
                rx="4.54485"
                transform="matrix(-1 0 0 1 342 0.325439)"
                fill={theme.colors.grayscalewhite}
              />
            </clipPath>
          </defs>
        </svg>
      </Display>
    </>
  )
}
