'use client'
import { Display } from '../../../common/style/Display'
import { theme } from '../../../styles/theme'

export function ShopMedicationsHero() {
  return (
    <>
      <Display isNotebookUp>
        <svg width="349" height="253" viewBox="0 0 349 253" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_11709_22439)">
            <rect width="318" height="253" rx="12.7575" fill="#C7C8DD" />
            <path
              d="M926.268 87.7354C935.969 203.669 923.925 251.766 906.698 330.798L46.2644 330.798C-46.2611 -163.932 171.931 107.195 530.515 114.542C629.53 116.571 914.14 -57.1821 926.268 87.7354Z"
              fill="#B8B9D4"
            />
            <g style={{ mixBlendMode: 'multiply' }} opacity="0.4">
              <ellipse cx="88.9707" cy="96.6305" rx="54.2476" ry="2.5238" fill="#D9D9D9" />
            </g>
            <rect x="88.8701" y="12.9036" width="57.1207" height="84.1508" rx="2.48917" fill="#9F9AB2" />
            <rect x="92.3883" y="21.842" width="51.7184" height="71.5966" rx="1.65944" fill="#8C879F" />
            <rect x="91.7698" y="21.0215" width="51.3214" height="71.4651" rx="0.829722" fill="#F2F2FB" />
            <rect x="122.319" y="73.5552" width="9.61593" height="9.61593" fill="#B8B9D4" />
            <rect x="102.772" y="46.0962" width="29.3168" height="4.69073" fill="#B8B9D4" />
            <rect x="102.772" y="55.2493" width="29.3168" height="4.69073" fill="#B8B9D4" />
            <rect x="102.772" y="64.4021" width="29.3168" height="4.69073" fill="#B8B9D4" />
            <path
              d="M104.527 32.4098L104.589 32.3216H104.697H105.933H106.33L106.103 32.6477L101.759 38.8777L101.697 38.9665H101.589H100.318H99.9182L100.148 38.6398L104.527 32.4098Z"
              fill="#9F9AB2"
              stroke="#9F9AB2"
              strokeWidth="0.414861"
            />
            <path
              d="M96.4179 35.4707V35.6781H96.6253H97.7203H97.9277V35.4707V32.1459H99.6984C99.7725 32.1459 99.8455 32.1446 99.9173 32.1421L104.763 38.8799L104.825 38.9662H104.931H106.203H106.609L106.371 38.6372L101.433 31.8167C101.462 31.8029 101.491 31.7887 101.52 31.774L101.521 31.7735C102.005 31.5204 102.371 31.1678 102.61 30.7152C102.846 30.2692 102.961 29.7627 102.961 29.201C102.961 28.6393 102.846 28.1303 102.611 27.679C102.372 27.2204 102.004 26.8632 101.517 26.6069C101.025 26.3449 100.409 26.2208 99.6807 26.2208H96.6253H96.4179V26.4282V35.4707ZM99.663 30.742H97.9277V27.607H99.6454C100.116 27.607 100.473 27.6797 100.731 27.8099L100.731 27.8099L100.732 27.8107C100.993 27.9398 101.175 28.118 101.288 28.3445C101.406 28.5814 101.469 28.8649 101.469 29.201C101.469 29.5373 101.406 29.8152 101.289 30.0418L101.289 30.0426C101.18 30.2557 101.001 30.4258 100.74 30.5502L100.739 30.5505C100.483 30.6735 100.128 30.742 99.663 30.742Z"
              fill="#9F9AB2"
              stroke="#9F9AB2"
              strokeWidth="0.414861"
            />
            <rect x="105.373" y="19.9536" width="23.9851" height="3.7773" rx="0.829722" fill="#9F9AB2" />
            <rect x="105.373" y="19.5012" width="23.985" height="3.56329" rx="0.829722" fill="#E0E0ED" />
            <rect x="113.611" y="14.6797" width="8.40785" height="4.68742" rx="0.829722" fill="#8D87A2" />
            <rect x="113.162" y="14.4033" width="8.40785" height="4.68742" rx="0.829722" fill="#E0E0ED" />
            <path
              d="M106.497 19.3656C113.641 17.6469 121.09 17.6469 128.234 19.3656L128.796 19.5009H105.934L106.497 19.3656Z"
              fill="#B3AEC6"
            />
            <circle cx="117.366" cy="16.3788" r="1.06461" fill="#B3AEC6" />
            <line x1="106.629" y1="21.539" x2="128.127" y2="21.539" stroke="#BDB9CA" strokeWidth="0.829722" />
            <g style={{ mixBlendMode: 'multiply' }} opacity="0.4">
              <ellipse cx="113.467" cy="194.77" rx="108.007" ry="5.23023" fill="#D9D9D9" />
            </g>
            <g clipPath="url(#clip1_11709_22439)">
              <rect x="52.4922" y="123" width="28.3946" height="72.5913" fill="#F5F5FD" />
              <rect x="80.8867" y="123" width="28.3946" height="72.5913" fill="#F5F5FD" />
              <rect x="109.281" y="123" width="28.3946" height="72.5913" fill="#F5F5FD" />
              <rect x="137.676" y="123" width="28.3946" height="72.5913" fill="#F5F5FD" />
              <rect x="166.07" y="123" width="28.3946" height="72.5913" fill="#F5F5FD" />
              <rect x="194.465" y="123" width="28.3946" height="72.5913" fill="#83839A" />
            </g>
            <rect x="64.0994" y="134.935" width="38.8426" height="14.1754" rx="1.65944" fill="#B8B9D4" />
            <rect x="64.0994" y="161.692" width="91.2148" height="8.93821" rx="1.65944" fill="#B8B9D4" />
            <rect x="64.0994" y="176.749" width="70.266" height="8.93821" rx="1.65944" fill="#B8B9D4" />
            <g style={{ mixBlendMode: 'multiply' }} opacity="0.4">
              <ellipse cx="89.3629" cy="196.063" rx="26.2919" ry="1.06599" fill="#D9D9D9" />
            </g>
            <g clipPath="url(#clip2_11709_22439)">
              <rect x="72.211" y="183.781" width="18.485" height="12.2814" fill="#FFCD19" />
              <rect x="90.696" y="183.781" width="18.485" height="12.2814" fill="#D15187" />
              <g style={{ mixBlendMode: 'multiply' }}>
                <path
                  d="M77.1117 192.76C103.923 192.76 110.619 194.384 108.667 185.191L112.646 197.988L73.8077 197.988C62.61 181.181 69.4457 192.76 77.1117 192.76Z"
                  fill="#D9D9D9"
                />
              </g>
              <rect
                opacity="0.4"
                x="77.0517"
                y="185.509"
                width="7.87523"
                height="2.81051"
                rx="1.40525"
                fill={theme.colors.grayscalewhite}
              />
            </g>
            <g style={{ mixBlendMode: 'multiply' }} opacity="0.4">
              <ellipse cx="45.3152" cy="200.51" rx="22.2056" ry="3.52975" fill="#D9D9D9" />
            </g>
            <g clipPath="url(#clip3_11709_22439)">
              <rect
                x="71.0006"
                y="204.591"
                width="18.485"
                height="12.2814"
                transform="rotate(-170.859 71.0006 204.591)"
                fill="#FFCD19"
              />
              <rect
                x="52.7499"
                y="201.654"
                width="18.485"
                height="12.2814"
                transform="rotate(-170.859 52.7499 201.654)"
                fill="#D15187"
              />
              <g style={{ mixBlendMode: 'multiply' }}>
                <path
                  d="M39.8476 196.233C66.3184 200.492 72.6712 203.16 72.2048 193.773L74.1 207.04L35.7549 200.87C27.3695 182.497 32.2789 195.015 39.8476 196.233Z"
                  fill="#D9D9D9"
                />
              </g>
              <rect
                opacity="0.4"
                x="40.9409"
                y="189.065"
                width="7.87523"
                height="2.81051"
                rx="1.40525"
                transform="rotate(9.14093 40.9409 189.065)"
                fill={theme.colors.grayscalewhite}
              />
            </g>
            <g style={{ mixBlendMode: 'multiply' }} opacity="0.4">
              <ellipse cx="111.588" cy="206.287" rx="19.8923" ry="4.07239" fill="#D9D9D9" />
            </g>
            <g clipPath="url(#clip4_11709_22439)">
              <rect
                x="113.669"
                y="199.067"
                width="18.485"
                height="12.2814"
                transform="rotate(-139.214 113.669 199.067)"
                fill="#FFCD19"
              />
              <rect
                x="99.6729"
                y="186.992"
                width="18.485"
                height="12.2814"
                transform="rotate(-139.214 99.6729 186.992)"
                fill="#D15187"
              />
              <g style={{ mixBlendMode: 'multiply' }}>
                <path
                  d="M81.9623 177.367C84.7234 157.36 81.6812 170.303 87.5846 175.193C94.6466 181.044 103.925 189.643 108.581 193.232C113.236 196.82 120.255 189.736 115.864 184.687L122.308 189.51L111.037 203.117L81.9623 177.367Z"
                  fill="#D9D9D9"
                />
              </g>
              <path
                opacity="0.4"
                d="M92.6899 171.87C93.1849 171.302 94.5729 170.505 96.1654 171.87"
                stroke={theme.colors.grayscalewhite}
                strokeWidth="2.69314"
                strokeLinecap="round"
              />
            </g>
            <g clipPath="url(#clip5_11709_22439)">
              <rect
                x="142.051"
                y="203.879"
                width="18.485"
                height="12.2814"
                transform="rotate(171.602 142.051 203.879)"
                fill="#FFCD19"
              />
              <rect
                x="123.764"
                y="206.578"
                width="18.485"
                height="12.2814"
                transform="rotate(171.602 123.764 206.578)"
                fill="#D15187"
              />
              <g style={{ mixBlendMode: 'multiply' }}>
                <path
                  d="M109.843 205.295C136.367 201.379 143.228 202.009 139.955 193.199L145.76 205.278L107.338 210.95C93.8055 195.958 102.259 206.415 109.843 205.295Z"
                  fill="#D9D9D9"
                />
              </g>
              <rect
                opacity="0.4"
                x="108.725"
                y="198.131"
                width="7.87523"
                height="2.81051"
                rx="1.40525"
                transform="rotate(-8.39803 108.725 198.131)"
                fill={theme.colors.grayscalewhite}
              />
            </g>
          </g>
          <g style={{ mixBlendMode: 'multiply' }} opacity="0.4">
            <ellipse cx="262.013" cy="215.742" rx="77.5131" ry="6.49423" fill="#D9D9D9" />
          </g>
          <rect x="260.22" y="77.4358" width="75.6152" height="140.023" rx="6.96176" fill="#F8931F" />
          <path
            d="M254.674 89.032C254.674 87.9335 255.565 87.043 256.663 87.043H339.039C340.138 87.043 341.028 87.9335 341.028 89.032V92.6851C341.028 94.8821 339.247 96.6632 337.05 96.6632H258.652C256.455 96.6632 254.674 94.8821 254.674 92.6851V89.032Z"
            fill="#F8931F"
          />
          <path
            d="M259.867 67.5461C259.867 63.6127 263.056 60.4241 266.989 60.4241H328.714C332.647 60.4241 335.836 63.6127 335.836 67.5461V79.4161C335.836 82.0384 333.71 84.1641 331.088 84.1641H264.615C261.993 84.1641 259.867 82.0384 259.867 79.4161V67.5461Z"
            fill="#2F3164"
          />
          <path
            d="M254.674 81.5731C254.674 79.2881 256.527 77.4358 258.812 77.4358H336.891C339.176 77.4358 341.028 79.2881 341.028 81.5731V85.2825C341.028 86.2547 340.24 87.0428 339.268 87.0428H256.435C255.462 87.0428 254.674 86.2547 254.674 85.2825V81.5731Z"
            fill="#2F3164"
          />
          <path d="M265.209 67.5459L265.209 80.6029" stroke="#6B6486" strokeWidth="1.187" strokeLinecap="round" />
          <path d="M271.143 67.5459L271.143 80.6029" stroke="#6B6486" strokeWidth="1.187" strokeLinecap="round" />
          <path d="M277.079 67.5459L277.079 80.6029" stroke="#6B6486" strokeWidth="1.187" strokeLinecap="round" />
          <path d="M283.014 67.5459L283.014 80.6029" stroke="#6B6486" strokeWidth="1.187" strokeLinecap="round" />
          <path d="M288.949 67.5459L288.949 80.6029" stroke="#6B6486" strokeWidth="1.187" strokeLinecap="round" />
          <path d="M294.884 67.5459L294.884 80.6029" stroke="#6B6486" strokeWidth="1.187" strokeLinecap="round" />
          <path d="M300.819 67.5459L300.819 80.6029" stroke="#6B6486" strokeWidth="1.187" strokeLinecap="round" />
          <path d="M306.753 67.5459L306.753 80.6029" stroke="#6B6486" strokeWidth="1.187" strokeLinecap="round" />
          <path d="M312.688 67.5459L312.688 80.6029" stroke="#6B6486" strokeWidth="1.187" strokeLinecap="round" />
          <path d="M318.624 67.5459L318.624 80.6029" stroke="#6B6486" strokeWidth="1.187" strokeLinecap="round" />
          <path d="M324.559 67.5459L324.559 80.6029" stroke="#6B6486" strokeWidth="1.187" strokeLinecap="round" />
          <path d="M330.494 67.5459L330.494 80.6029" stroke="#6B6486" strokeWidth="1.187" strokeLinecap="round" />
          <rect x="260.22" y="116.241" width="75.6152" height="73.3503" fill="#F6F6F6" />
          <rect x="270.067" y="143.366" width="55.101" height="9.10599" fill="#E7E6E6" />
          <rect x="270.067" y="156.295" width="31.2321" height="9.10599" fill="#E7E6E6" />
          <rect x="270.067" y="176.162" width="39.1884" height="4.1333" fill="#E7E6E6" />
          <rect x="270.067" y="124.484" width="15.6161" height="14.2707" fill="#E7E6E6" />
          <g style={{ mixBlendMode: 'multiply' }} opacity="0.1">
            <path
              d="M319.532 87.0466L339.022 87.0467C340.121 87.0467 341.011 87.9372 341.011 89.0357V90.3696V92.6752C341.011 94.8723 339.23 96.6534 337.033 96.6534L335.835 96.6534V210.486C335.835 214.331 332.718 217.448 328.874 217.448H314.089V99.5121L294.697 96.6534H315.554C317.751 96.6534 319.532 94.8723 319.532 92.6752V90.3696V87.0466Z"
              fill="#777777"
            />
          </g>
          <g style={{ mixBlendMode: 'lighten' }} opacity="0.1">
            <path
              d="M268.197 87.0466L256.664 87.0467C255.565 87.0467 254.675 87.9372 254.675 89.0357V90.3696V92.6752C254.675 94.8723 256.456 96.6534 258.653 96.6534L260.229 96.6534V210.486C260.229 214.331 263.346 217.448 267.191 217.448H277.724C275.113 214.909 273.64 211.422 273.64 207.781V99.5121L273.64 96.6534H272.176C269.979 96.6534 268.197 94.8723 268.197 92.6752V90.3696V87.0466Z"
              fill={theme.colors.grayscalewhite}
            />
          </g>
          <defs>
            <clipPath id="clip0_11709_22439">
              <rect width="318" height="253" rx="12.7575" fill={theme.colors.grayscalewhite} />
            </clipPath>
            <clipPath id="clip1_11709_22439">
              <rect
                x="52.4922"
                y="123"
                width="170.367"
                height="72.5913"
                rx="2.48917"
                fill={theme.colors.grayscalewhite}
              />
            </clipPath>
            <clipPath id="clip2_11709_22439">
              <rect
                x="72.211"
                y="183.781"
                width="36.9701"
                height="12.2814"
                rx="6.14069"
                fill={theme.colors.grayscalewhite}
              />
            </clipPath>
            <clipPath id="clip3_11709_22439">
              <rect
                x="71.0006"
                y="204.591"
                width="36.9701"
                height="12.2814"
                rx="6.14069"
                transform="rotate(-170.859 71.0006 204.591)"
                fill={theme.colors.grayscalewhite}
              />
            </clipPath>
            <clipPath id="clip4_11709_22439">
              <rect
                x="113.669"
                y="199.067"
                width="36.9701"
                height="12.2814"
                rx="6.14069"
                transform="rotate(-139.214 113.669 199.067)"
                fill={theme.colors.grayscalewhite}
              />
            </clipPath>
            <clipPath id="clip5_11709_22439">
              <rect
                x="142.051"
                y="203.879"
                width="36.9701"
                height="12.2814"
                rx="6.14069"
                transform="rotate(171.602 142.051 203.879)"
                fill={theme.colors.grayscalewhite}
              />
            </clipPath>
          </defs>
        </svg>
      </Display>
      <Display isNotebookDown>
        <svg width="100%" height="100%" viewBox="0 0 960 325" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_11357_54506)">
            <rect y="0.441895" width="960" height="285.449" rx="12.7575" fill="#C7C8DD" />
            <path
              d="M925.268 96.1771C934.969 212.111 922.925 260.207 905.698 339.239L45.2644 339.239C-47.2611 -155.491 170.931 115.636 529.515 122.984C628.53 125.012 913.14 -48.7405 925.268 96.1771Z"
              fill="#B8B9D4"
            />
            <g style={{ mixBlendMode: 'multiply' }} opacity="0.4">
              <ellipse cx="452.068" cy="182.265" rx="119.637" ry="4.85061" fill="#D9D9D9" />
            </g>
            <rect x="328.87" y="21.3457" width="109.783" height="161.734" rx="4.78405" fill="#9F9AB2" />
            <rect x="335.632" y="38.5254" width="99.4001" height="137.605" rx="3.18937" fill="#8C879F" />
            <rect x="334.443" y="36.9482" width="98.6372" height="137.352" rx="1.59468" fill="#F2F2FB" />
            <rect x="393.157" y="137.915" width="18.4813" height="18.4813" fill="#B8B9D4" />
            <rect x="355.589" y="85.1406" width="56.3453" height="9.01534" fill="#B8B9D4" />
            <rect x="355.589" y="102.732" width="56.3453" height="9.01534" fill="#B8B9D4" />
            <rect x="355.589" y="120.324" width="56.3453" height="9.01534" fill="#B8B9D4" />
            <path
              d="M358.962 58.8352L359.081 58.6658H359.288H361.664H362.428L361.991 59.2925L353.643 71.2662L353.524 71.4369H353.316H350.872H350.104L350.546 70.8089L358.962 58.8352Z"
              fill="#9F9AB2"
              stroke="#9F9AB2"
              strokeWidth="0.797342"
            />
            <path
              d="M343.377 64.7185V65.1172H343.775H345.88H346.279V64.7185V58.3284H349.682C349.824 58.3284 349.964 58.326 350.103 58.3211L359.415 71.2709L359.535 71.4368H359.739H362.183H362.964L362.506 70.8043L353.016 57.6958C353.072 57.6693 353.128 57.6419 353.183 57.6136L353.184 57.6127C354.115 57.1262 354.819 56.4486 355.278 55.5788C355.731 54.7216 355.953 53.748 355.953 52.6685C355.953 51.5888 355.731 50.6106 355.279 49.7433C354.82 48.862 354.114 48.1754 353.178 47.6828C352.231 47.1792 351.047 46.9407 349.648 46.9407H343.775H343.377V47.3394V64.7185ZM349.614 55.6302H346.279V49.6049H349.58C350.483 49.6049 351.17 49.7446 351.666 49.9949L351.666 49.9949L351.669 49.9963C352.17 50.2446 352.519 50.587 352.736 51.0223C352.964 51.4776 353.085 52.0225 353.085 52.6685C353.085 53.3149 352.964 53.849 352.739 54.2845L352.738 54.286C352.529 54.6956 352.186 55.0224 351.683 55.2615L351.682 55.2622C351.189 55.4985 350.508 55.6302 349.614 55.6302Z"
              fill="#9F9AB2"
              stroke="#9F9AB2"
              strokeWidth="0.797342"
            />
            <rect x="360.588" y="34.8955" width="46.0981" height="7.25979" rx="1.59468" fill="#9F9AB2" />
            <rect x="360.588" y="34.0259" width="46.098" height="6.84846" rx="1.59468" fill="#E0E0ED" />
            <rect x="376.421" y="24.7593" width="16.1595" height="9.00899" rx="1.59468" fill="#8D87A2" />
            <rect x="375.559" y="24.2285" width="16.1595" height="9.00899" rx="1.59468" fill="#E0E0ED" />
            <path
              d="M362.748 33.7654C376.478 30.4621 390.796 30.4621 404.526 33.7654L405.606 34.0254H361.667L362.748 33.7654Z"
              fill="#B3AEC6"
            />
            <circle cx="383.637" cy="28.0246" r="2.04613" fill="#B3AEC6" />
            <line x1="363.002" y1="37.9424" x2="404.321" y2="37.9424" stroke="#BDB9CA" strokeWidth="1.59468" />
            <g style={{ mixBlendMode: 'multiply' }} opacity="0.4">
              <ellipse cx="723.149" cy="247.188" rx="208.59" ry="6.49361" fill="#D9D9D9" />
            </g>
            <g clipPath="url(#clip1_11357_54506)">
              <rect x="506.018" y="107.328" width="54.5729" height="139.517" fill="#F5F5FD" />
              <rect x="560.591" y="107.328" width="54.5729" height="139.517" fill="#F5F5FD" />
              <rect x="615.164" y="107.328" width="54.5729" height="139.517" fill="#F5F5FD" />
              <rect x="669.736" y="107.328" width="54.5729" height="139.517" fill="#F5F5FD" />
              <rect x="724.309" y="107.328" width="54.5729" height="139.517" fill="#F5F5FD" />
              <rect x="778.882" y="107.328" width="54.5729" height="139.517" fill="#83839A" />
            </g>
            <rect x="528.326" y="130.267" width="74.6536" height="27.2445" rx="3.18937" fill="#B8B9D4" />
            <rect x="528.326" y="181.692" width="175.31" height="17.1788" rx="3.18937" fill="#B8B9D4" />
            <rect x="528.326" y="210.631" width="135.048" height="17.1788" rx="3.18937" fill="#B8B9D4" />
            <g style={{ mixBlendMode: 'multiply' }} opacity="0.4">
              <ellipse cx="607.632" cy="247.749" rx="50.5318" ry="2.04879" fill="#D9D9D9" />
            </g>
            <g clipPath="url(#clip2_11357_54506)">
              <rect x="543.916" y="224.145" width="35.5273" height="23.6042" fill="#FFCD19" />
              <rect x="579.443" y="224.145" width="35.5273" height="23.6042" fill="#D15187" />
              <g style={{ mixBlendMode: 'multiply' }}>
                <path
                  d="M553.335 241.401C604.865 241.401 617.734 244.524 613.983 226.854L621.63 251.45L546.985 251.451C525.463 219.147 538.601 241.401 553.335 241.401Z"
                  fill="#D9D9D9"
                />
              </g>
              <rect
                opacity="0.4"
                x="553.22"
                y="227.465"
                width="15.1358"
                height="5.40165"
                rx="2.70083"
                fill={theme.colors.grayscalewhite}
              />
            </g>
            <g style={{ mixBlendMode: 'multiply' }} opacity="0.4">
              <ellipse cx="522.975" cy="256.298" rx="42.678" ry="6.784" fill="#D9D9D9" />
            </g>
            <g clipPath="url(#clip3_11357_54506)">
              <rect
                x="541.59"
                y="264.141"
                width="35.5273"
                height="23.6042"
                transform="rotate(-170.859 541.59 264.141)"
                fill="#FFCD19"
              />
              <rect
                x="506.513"
                y="258.497"
                width="35.5273"
                height="23.6042"
                transform="rotate(-170.859 506.513 258.497)"
                fill="#D15187"
              />
              <g style={{ mixBlendMode: 'multiply' }}>
                <path
                  d="M481.715 248.077C532.591 256.263 544.801 261.39 543.904 243.349L547.547 268.848L473.849 256.99C457.733 221.678 467.169 245.736 481.715 248.077Z"
                  fill="#D9D9D9"
                />
              </g>
              <rect
                opacity="0.4"
                x="483.817"
                y="234.3"
                width="15.1358"
                height="5.40165"
                rx="2.70083"
                transform="rotate(9.14093 483.817 234.3)"
                fill={theme.colors.grayscalewhite}
              />
            </g>
            <g style={{ mixBlendMode: 'multiply' }} opacity="0.4">
              <ellipse cx="650.347" cy="267.401" rx="38.232" ry="7.82692" fill="#D9D9D9" />
            </g>
            <g clipPath="url(#clip4_11357_54506)">
              <rect
                x="623.596"
                y="253.524"
                width="35.5273"
                height="23.6042"
                transform="rotate(-139.214 623.596 253.524)"
                fill="#FFCD19"
              />
              <rect
                x="596.696"
                y="230.317"
                width="35.5273"
                height="23.6042"
                transform="rotate(-139.214 596.696 230.317)"
                fill="#D15187"
              />
              <g style={{ mixBlendMode: 'multiply' }}>
                <path
                  d="M562.658 211.817C567.964 173.366 562.117 198.241 573.463 207.641C587.036 218.884 604.869 235.413 613.817 242.309C622.765 249.206 636.254 235.591 627.814 225.886L640.201 235.155L618.537 261.309L562.658 211.817Z"
                  fill="#D9D9D9"
                />
              </g>
              <path
                opacity="0.4"
                d="M583.276 201.254C584.227 200.161 586.895 198.631 589.955 201.254"
                stroke={theme.colors.grayscalewhite}
                strokeWidth="5.17608"
                strokeLinecap="round"
              />
            </g>
            <g clipPath="url(#clip5_11357_54506)">
              <rect
                x="678.146"
                y="262.772"
                width="35.5273"
                height="23.6042"
                transform="rotate(171.602 678.146 262.772)"
                fill="#FFCD19"
              />
              <rect
                x="642.999"
                y="267.96"
                width="35.5273"
                height="23.6042"
                transform="rotate(171.602 642.999 267.96)"
                fill="#D15187"
              />
              <g style={{ mixBlendMode: 'multiply' }}>
                <path
                  d="M616.242 265.495C667.22 257.969 680.407 259.178 674.116 242.246L685.273 265.461L611.428 276.364C585.42 247.55 601.667 267.647 616.242 265.495Z"
                  fill="#D9D9D9"
                />
              </g>
              <rect
                opacity="0.4"
                x="614.094"
                y="251.725"
                width="15.1358"
                height="5.40165"
                rx="2.70083"
                transform="rotate(-8.39803 614.094 251.725)"
                fill={theme.colors.grayscalewhite}
              />
            </g>
          </g>
          <g style={{ mixBlendMode: 'multiply' }} opacity="0.4">
            <ellipse cx="313.332" cy="301.088" rx="164.288" ry="10.4131" fill="#D9D9D9" />
          </g>
          <rect x="139.457" y="79.3213" width="121.245" height="224.52" rx="11.1628" fill="#F8931F" />
          <path
            d="M130.564 97.915C130.564 96.1535 131.992 94.7256 133.754 94.7256H265.839C267.6 94.7256 269.028 96.1535 269.028 97.915V103.772C269.028 107.295 266.172 110.151 262.65 110.151H136.943C133.42 110.151 130.564 107.295 130.564 103.772V97.915Z"
            fill="#F8931F"
          />
          <path
            d="M138.891 63.4637C138.891 57.1567 144.004 52.0439 150.311 52.0439H249.282C255.589 52.0439 260.702 57.1567 260.702 63.4637V82.4966C260.702 86.7013 257.294 90.1098 253.089 90.1098H146.505C142.3 90.1098 138.891 86.7013 138.891 82.4966V63.4637Z"
            fill="#2F3164"
          />
          <path
            d="M130.564 85.9552C130.564 82.2914 133.535 79.3213 137.198 79.3213H262.394C266.058 79.3213 269.028 82.2914 269.028 85.9552V91.903C269.028 93.4619 267.765 94.7256 266.206 94.7256H133.387C131.828 94.7256 130.564 93.4619 130.564 91.903V85.9552Z"
            fill="#2F3164"
          />
          <path d="M147.457 63.4639L147.457 84.4001" stroke="#6B6486" strokeWidth="1.90329" strokeLinecap="round" />
          <path d="M156.972 63.4639L156.972 84.4001" stroke="#6B6486" strokeWidth="1.90329" strokeLinecap="round" />
          <path d="M166.488 63.4639L166.488 84.4001" stroke="#6B6486" strokeWidth="1.90329" strokeLinecap="round" />
          <path d="M176.005 63.4639L176.005 84.4001" stroke="#6B6486" strokeWidth="1.90329" strokeLinecap="round" />
          <path d="M185.521 63.4639L185.521 84.4001" stroke="#6B6486" strokeWidth="1.90329" strokeLinecap="round" />
          <path d="M195.039 63.4639L195.039 84.4001" stroke="#6B6486" strokeWidth="1.90329" strokeLinecap="round" />
          <path d="M204.556 63.4639L204.556 84.4001" stroke="#6B6486" strokeWidth="1.90329" strokeLinecap="round" />
          <path d="M214.07 63.4639L214.07 84.4001" stroke="#6B6486" strokeWidth="1.90329" strokeLinecap="round" />
          <path d="M223.587 63.4639L223.587 84.4001" stroke="#6B6486" strokeWidth="1.90329" strokeLinecap="round" />
          <path d="M233.104 63.4639L233.104 84.4001" stroke="#6B6486" strokeWidth="1.90329" strokeLinecap="round" />
          <path d="M242.621 63.4639L242.621 84.4001" stroke="#6B6486" strokeWidth="1.90329" strokeLinecap="round" />
          <path d="M252.138 63.4639L252.138 84.4001" stroke="#6B6486" strokeWidth="1.90329" strokeLinecap="round" />
          <rect x="139.457" y="141.543" width="121.245" height="117.613" fill="#F6F6F6" />
          <rect x="155.245" y="185.037" width="88.3513" height="14.6009" fill="#E7E6E6" />
          <rect x="155.245" y="205.768" width="50.0789" height="14.6009" fill="#E7E6E6" />
          <rect x="155.245" y="237.624" width="62.8363" height="6.62752" fill="#E7E6E6" />
          <rect x="155.245" y="154.76" width="25.0395" height="22.8823" fill="#E7E6E6" />
          <g style={{ mixBlendMode: 'multiply' }} opacity="0.1">
            <path
              d="M234.56 94.7319L265.811 94.732C267.573 94.732 269.001 96.1599 269.001 97.9214V100.06V103.757C269.001 107.28 266.145 110.136 262.622 110.136L260.702 110.136V292.661C260.702 298.826 255.704 303.823 249.539 303.823H225.833V114.72L194.739 110.136H228.181C231.704 110.136 234.56 107.28 234.56 103.757V100.06V94.7319Z"
              fill="#777777"
            />
          </g>
          <g style={{ mixBlendMode: 'lighten' }} opacity="0.1">
            <path
              d="M152.248 94.7314L133.754 94.7315C131.993 94.7315 130.565 96.1594 130.565 97.9209V100.06V103.757C130.565 107.28 133.421 110.135 136.944 110.135L139.471 110.135V292.66C139.471 298.825 144.469 303.823 150.634 303.823H167.523C163.337 299.752 160.975 294.161 160.975 288.322V114.719L160.975 110.135H158.627C155.104 110.135 152.248 107.279 152.248 103.757V100.06V94.7314Z"
              fill={theme.colors.grayscalewhite}
            />
          </g>
          <defs>
            <clipPath id="clip0_11357_54506">
              <rect y="0.441895" width="960" height="285.449" rx="12.7575" fill={theme.colors.grayscalewhite} />
            </clipPath>
            <clipPath id="clip1_11357_54506">
              <rect
                x="506.018"
                y="107.328"
                width="327.438"
                height="139.517"
                rx="4.78405"
                fill={theme.colors.grayscalewhite}
              />
            </clipPath>
            <clipPath id="clip2_11357_54506">
              <rect
                x="543.916"
                y="224.145"
                width="71.0546"
                height="23.6042"
                rx="11.8021"
                fill={theme.colors.grayscalewhite}
              />
            </clipPath>
            <clipPath id="clip3_11357_54506">
              <rect
                x="541.59"
                y="264.141"
                width="71.0546"
                height="23.6042"
                rx="11.8021"
                transform="rotate(-170.859 541.59 264.141)"
                fill={theme.colors.grayscalewhite}
              />
            </clipPath>
            <clipPath id="clip4_11357_54506">
              <rect
                x="623.596"
                y="253.524"
                width="71.0546"
                height="23.6042"
                rx="11.8021"
                transform="rotate(-139.214 623.596 253.524)"
                fill={theme.colors.grayscalewhite}
              />
            </clipPath>
            <clipPath id="clip5_11357_54506">
              <rect
                x="678.146"
                y="262.772"
                width="71.0546"
                height="23.6042"
                rx="11.8021"
                transform="rotate(171.602 678.146 262.772)"
                fill={theme.colors.grayscalewhite}
              />
            </clipPath>
          </defs>
        </svg>
      </Display>
    </>
  )
}
